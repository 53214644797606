.editButton {
  margin-right: 16px;
}
.helpButton {
  display: block !important;
  margin-bottom: 20px;
}
.officialReasonText > span > div {
  display: inline;
}
.previewEmailCard {
  margin-top: 16px !important;
}

.generalProjectDataBody {
  margin-left: 16px;
}

@primary-color: #FE5816;@font-size-base: 16px;