@import '../../../../styles/variables.less';
.tabs {
  background-color: white;
  margin: 0 !important;
  :global(.ant-tabs-nav-wrap) {
    background-color: #fafafa;
  }
  :global(.ant-tabs-content-holder) {
    padding: 0 16px;
  }
  :global(.ant-tabs-tab) {
    border: none !important;
  }
}
.empty {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: @gray-6;
}

@primary-color: #FE5816;@font-size-base: 16px;