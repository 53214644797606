.card {
  margin-bottom: 16px;
  > :first-child {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .collapse {
    margin-left: -16px !important;
  }
}

@primary-color: #FE5816;@font-size-base: 16px;