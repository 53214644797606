@footerHeight: 73px;
@headerHeight: 64px;

.playerWrapper {
  position: relative;
  padding-top: 56.25%;
}

.stickyWrapper {
  top: calc(@headerHeight / 2);
  position: sticky;
}

.container {
  position: relative;
  min-height: calc(100vh - 80px - @footerHeight - @headerHeight);
}

@primary-color: #FE5816;@font-size-base: 16px;