.btn {
  display: inline-flex !important;
  align-items: center;
  margin-right: 10px;
  margin-bottom: 10px;
  svg {
    margin-right: 8px;
  }
  border-radius: 20px !important;
  padding: 5px 20px !important;
}

@primary-color: #FE5816;@font-size-base: 16px;