.phoneInput {
  display: flex !important;
  > :first-child {
    width: 115px !important;
    > div {
      height: 100% !important;
      > :last-child::first-letter {
        margin-right: 5px;
      }
    }
  }
  > :last-child {
    margin-left: 1px;
    flex: 1;
  }
}

@primary-color: #FE5816;@font-size-base: 16px;