.ant-input-group {
  display: inline-table;
  &.addonAfterInput {
    .ant-input-number {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }
  }
}
.ant-input-wrapper {
  height: fit-content;
}

@primary-color: #FE5816;@font-size-base: 16px;