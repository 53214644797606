.colorPreview {
  border-radius: 4px;
  box-shadow: 0 0 2px black;
  margin-left: 15px;

  width: 25px;
  height: 25px;
  display: inline-block;
}

.picker {
  > :last-child {
    > :first-child {
      align-items: center;
      > :first-child > div {
        margin-top: 0 !important;
      }

      > :last-child {
        margin-left: 15px;

        height: 10px;
        width: 100%;
        flex: unset !important;

        > :last-child {
          display: none !important;
        }
      }
    }
    > :last-child {
      display: none !important;
    }
  }
}

.containerColorPickerInput {
  display: flex;
  .colorHexInput {
    position: relative;
    display: flex;
    align-items: center;
    flex: 1;
    > input {
      height: 100%;
      padding-right: 45px !important;
    }
    > div {
      position: absolute;
      right: 5px;
    }
  }
}
.dropdown {
  max-width: 42px;
  border-top-left-radius: unset !important;
  border-bottom-left-radius: unset !important;
}
.colorPiker {
  user-select: none;
}

@primary-color: #FE5816;@font-size-base: 16px;