.layout {
  min-height: 100vh !important;
}
.header {
  padding-top: 20px !important;
  padding-bottom: 20px !important;
  line-height: normal !important;
  text-align: center;
  background-color: white !important;
  display: flex;
  align-items: center;
  justify-content: center;
}
.content {
  display: flex;
}
.logo {
  height: 30px;
  width: auto;
}

@primary-color: #FE5816;@font-size-base: 16px;