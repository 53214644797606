.corporatePrimaryColorForm {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  > :first-child {
    margin-right: 20px;
    > div {
      min-width: 168px;
      max-width: 168px;
    }
  }
}

.styleButton {
  display: flex;
  > :not(:first-child) {
    margin-left: 15px;
  }
  :global(.ant-row) {
    margin: 0px;
    :global(.ant-btn) {
      min-width: 30px;
      min-height: 30px;
    }
  }
}

.tableCorporatePrimaryColour {
  min-width: unset !important;
  max-width: 600px !important;
}

.table {
  margin-top: 20px;
  padding: 16px;
  > :first-child {
    margin: 0 0 0 -16px;
  }
}

.tableOfContentFields,
.tableFields {
  display: flex;
  flex-wrap: wrap;
  > div {
    margin-top: 16px;
    margin-right: 16px;
    min-width: 215px;
    max-width: 215px;
    > div {
      margin: 0;
    }
  }
}

.fontInputWrapper {
  min-width: 160px;
  max-width: 160px;
  > div {
    width: 100%;
  }
}
.collapsePanel {
  > :last-child > div > :last-child {
    margin-bottom: 0;
  }
}

@primary-color: #FE5816;@font-size-base: 16px;