@import 'components/layouts/SignDocuments.variables.less';

.panel {
  display: flex;
  flex-direction: column;
  padding: 15px;
  border-radius: 10px;
  border: 1px solid #aaa;
  @media screen and (max-device-width: 500px) {
    padding: 12px;
  }
  .title {
    margin-bottom: 10px;
    svg {
      color: #5d5d5d;
    }
  }
}

@primary-color: #FE5816;@font-size-base: 16px;