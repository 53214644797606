.container {
  display: flex;
  flex-direction: column;
  > :first-child {
    width: fit-content;
  }
  .resetPassword {
    margin-top: 16px;
    flex: 1;
  }
}

@primary-color: #FE5816;@font-size-base: 16px;