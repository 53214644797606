.navbar {
  border: 0 !important;
  overflow-x: hidden;
  overflow-y: auto;

  display: flex;
  flex-direction: column;
  flex: 1;
  flex-basis: content;
  .ant-menu-item {
    min-height: 40px !important;
    margin: 4px 0 !important;
    > div {
      margin: 0 !important;
    }
  }
}

@primary-color: #FE5816;@font-size-base: 16px;