.button-container {
  display: inline-block;
  position: relative;

  .above-button {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 1;
    cursor: not-allowed;
  }
}

@primary-color: #FE5816;@font-size-base: 16px;