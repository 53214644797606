.companyItem {
  display: flex;
  justify-content: space-between;
  align-items: center;

  padding: 5px 12px;

  > :first-child {
    overflow: hidden;

    flex: 1;
    height: 100%;

    margin-right: 15px;

    white-space: nowrap;
    text-overflow: ellipsis;
  }
  button {
    width: 78px;
  }
}

.emptyOption {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  > :first-child {
    overflow: hidden;

    flex: 1;
    flex-wrap: nowrap;
    height: 100%;

    margin-right: 15px;
    word-break: break-all;
  }
}

.group {
  display: flex;
  > :not(:last-child) {
    margin-right: 16px;
  }
  @media (max-width: 450px) {
    flex-direction: column;
    > :not(:last-child) {
      margin-right: 0;
    }
  }
}

.itemRender {
  display: contents;
  margin: 5px 16px;
  width: 100%;
  text-overflow: ellipsis;
  word-break: break-all;
}

.emailAlertActionButton {
  border: 0px !important;
  padding: 0px !important;
  min-height: 20px !important;
}

@primary-color: #FE5816;@font-size-base: 16px;