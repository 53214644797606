button.linkButton {
  padding: 0;
  text-align: unset;
  &:hover {
    color: var(--primary-color) !important;
  }
  color: #1890ff !important;
  > span {
    text-decoration: underline;
  }
}

@primary-color: #FE5816;@font-size-base: 16px;