@import 'components/layouts/SignDocuments.variables.less';

.pricingOverview {
  .rowContainer {
    &.header {
      margin-bottom: 5px;
    }
    &:not(.header) {
      font-style: italic;
    }
    > :first-child {
      width: 200px;
      @media screen and (max-device-width: 500px) {
        width: 50%;
      }
    }
    @media screen and (max-device-width: 500px) {
      > :last-child {
        margin-left: auto;
      }
      > * {
        padding-left: 5px;
        padding-right: 5px;
      }
      :not(:last-child) {
        margin-bottom: 5px;
      }
    }
    display: flex;
  }
}

@primary-color: #FE5816;@font-size-base: 16px;