.ShortKeyValueBlockRow {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.ShortKeyValueBlockKeyColumn {
  width: 200px;
}

.ShortKeyValueBlockValueColumn {
  flex-shrink: 1;
}

@primary-color: #FE5816;@font-size-base: 16px;