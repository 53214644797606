@import 'styles/variables.less';

@width-drawer: 250px;

.trigger {
  display: flex;
  align-items: center;

  padding-left: 26.5px;

  &:hover {
    color: var(--primary-color);
    background-color: var(--hover-primary-color);
    cursor: pointer;
  }
}
.sider {
  border-right: 2px solid #d9d9d9;
  overflow: hidden;
  height: 100vh;
  position: fixed !important;
  left: 0;
  z-index: 50;
  > :last-child {
    margin-left: -2px;
    display: flex;
    flex-direction: column;
  }
}
.siderTrigger {
  transition: all 0.2s;
  height: 64px;
  display: flex;
  align-items: center;
  > div {
    height: 32px;
    padding: 0 6px;
    border-radius: 0 5px 5px 0;
    background-color: white;
  }
  position: fixed;
  left: 80px;
  height: 64px;
}
.siderOpen {
  transition: all 0.2s;
  left: @width-drawer;
}
.drawer {
  > :last-child > div > div > div {
    padding: 0;
  }
}
.hideLogoText {
  background-color: white;

  position: absolute;
  right: 0;
  top: 0;

  width: 24px;
  height: 64px;

  z-index: 80;
}
.logo {
  margin-left: 4px;
}

@primary-color: #FE5816;@font-size-base: 16px;