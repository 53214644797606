.discounts {
  ul {
    margin-bottom: 0;
  }
}
.totalPricing {
  > :last-child {
    padding: 0;
  }
  .panelCollapse {
    margin: 0;
    line-height: unset;
    > :first-child {
      padding: 0 !important;
      > :first-child {
        padding: 0 !important;
      }
    }
  }
  .totalPricingContent {
    margin-top: 0px;
  }
}
.collapseExpandIcon {
  margin-top: -4px !important;
}

@primary-color: #FE5816;@font-size-base: 16px;