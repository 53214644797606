@import 'styles/variables.less';

.card {
  padding-top: 0px !important;

  h1 {
    font-size: 20px;
    font-weight: 600;
    margin: 0;
  }

  h2 {
    font-size: 16px;
    font-weight: 600;
    margin: 0;
  }

  b {
    font-weight: 600;
  }
}

.cartTitle {
  display: flex;
  align-items: center;

  font-size: @shopping-cart-title-font-size;

  > :first-child {
    margin-right: 16px;
    min-width: fit-content;
    width: auto;
  }
  .title {
    flex: 1;
    white-space: normal;
  }
  &,
  * {
    font-weight: 500;
    font-size: 18px;
  }
}

@primary-color: #FE5816;@font-size-base: 16px;