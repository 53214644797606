@import 'styles/variables.less';

body div.alert {
  &,
  * {
    color: var(--primary-color) !important;
  }
  border-radius: 30px;
  background-color: @gray-4;
  border-color: @gray-4;
  padding: 10px 10px 10px 19px;
  margin-bottom: 24px !important;
}

@primary-color: #FE5816;@font-size-base: 16px;