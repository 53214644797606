.siteCollapseCustomPanel {
  :global(.ant-collapse-content-box) {
    background-color: #fafafa;
  }
  > :first-child {
    display: flex;
    flex: 1;
    overflow: hidden;
    :global(.ant-collapse-extra) {
      margin-left: 10px;
      align-self: flex-end;
    }
  }
}

@primary-color: #FE5816;@font-size-base: 16px;