.container {
  > :not(:first-child) {
    margin-top: 20px;
  }
}
.actions {
  display: flex;
  flex-wrap: wrap;
}

.downloadButton {
  display: inline-flex !important;
  align-items: center;
  margin-right: 8px;
  > span > svg {
    vertical-align: -4px;
  }
}

@primary-color: #FE5816;@font-size-base: 16px;