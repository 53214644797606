.selectionModal {
  width: 770px !important;

  .selectionModalContent {
    min-height: 100px;

    .icons {
      margin-top: 5px;
    }

    .whiteSpaceBetween {
      margin-bottom: 8px;
    }
  }

  .option1,
  .option2 {
    background-color: var(--primary-color);
    color: var(--btn-primary-color);
  }

  @media screen and (max-width: 752px) {
    .option1 {
      display: block;
      margin-right: 0px;
      margin-left: auto;
      margin-bottom: 10px;
    }
  }
}

@primary-color: #FE5816;@font-size-base: 16px;