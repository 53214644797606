.group {
  display: flex;
  flex: 1;
  flex-direction: column;
  @media (min-width: 768px) {
    flex-direction: row;
    & > :not(:last-child) {
      margin-right: 20px;
    }
  }
}
.preferences {
  > :not(:first-child) {
    margin-top: 16px;
  }
}

.rowOfCards {
  :global(.ant-col) {
    :global(.ant-card) {
      margin-bottom: 0px;
    }
  }
}

@primary-color: #FE5816;@font-size-base: 16px;