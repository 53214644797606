@import '~antd/lib/style/index';

.header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-direction: column;
  @media (min-width: @screen-md) {
    align-items: center;
    flex-direction: row;
  }
  h1 {
    margin-bottom: 0;
  }
}
.content {
  > :first-child {
    margin-top: 20px;
  }
}

@primary-color: #FE5816;@font-size-base: 16px;