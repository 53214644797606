.draggable-decorator {
  position: absolute;
  left: 1px;
  top: 1px;
  width: 5px;
  background-color: var(--primary-color);
  border-top-left-radius: 1px;
  border-bottom-left-radius: 1px;
}

@primary-color: #FE5816;@font-size-base: 16px;