.menu {
  & > * {
    display: inline-block;
  }

  & > * + * {
    margin-left: 7px;
  }
}
.toolbar {
  position: relative;
  padding: 6px;
  // margin: 0 -20px;
  border-bottom: 2px solid #eee;
  margin-bottom: 5px;

  border: 1px solid #f1f1f1;
  display: flex;
  flex-wrap: wrap;
  > button {
    min-width: 32px !important;
    min-height: 32px !important;
  }
  > * {
    margin-bottom: auto;
    margin-top: auto;
  }
}
.editorValue {
  overflow-x: auto;
  ol,
  ul {
    ol,
    ul {
      margin-bottom: unset;
      margin-top: unset;
    }
  }
  padding: 3px 11px;
  border: 1px solid #d9d9d9;
  > div > * {
    &:first-child {
      margin-top: 0px !important;
    }
    &:last-child {
      margin-bottom: 0px !important;
    }
  }
  a {
    all: revert !important;
    color: revert !important;
  }
}

:global(.slate) {
  > div {
    background: white;
  }
  button > svg {
    display: flex;
    margin: auto;
  }
}

@primary-color: #FE5816;@font-size-base: 16px;