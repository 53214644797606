.headerActiveName,
.headerInactiveName {
  height: auto;
  word-break: break-all;
  padding-left: 50px;
  flex: 1;
  max-width: 100%;
}
.headerInactiveName {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

@primary-color: #FE5816;@font-size-base: 16px;