.controlImage {
  display: flex;
  align-items: center;
  justify-content: center;

  margin-right: 16px;
  > div {
    align-items: center;
    justify-content: center;
    > span {
      display: flex;
      flex-direction: column;
      > button {
        display: block;
        margin-top: 16px !important;
      }
    }
  }
}

.containerFormikImage {
  padding: 20px;
  @media (min-width: 768px) {
    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-end;
  }
  > :first-child > div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width: 400px;

    > img {
      width: unset !important;
      max-width: 100%;
      max-height: 200px;
      margin: 0 !important;
    }
  }
}

@primary-color: #FE5816;@font-size-base: 16px;