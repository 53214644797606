@import 'styles/variables.less';

.actionCard {
  > :last-child {
    display: grid;
    gap: 10px;
    &::before {
      content: unset;
    }
    grid-template-columns: 1fr 1fr 1fr 1fr;
    @media (max-width: 1000px) {
      grid-template-columns: 1fr 1fr;
    }
    @media (max-width: 400px) {
      grid-template-columns: 1fr;
    }

    a {
      display: flex;
      button.buttonAction,
      button.primaryButtonAction {
        border-radius: 30px;

        display: inline-flex;
        flex-direction: column;
        align-items: center;
        flex: 1;
        height: fit-content;
        > :first-child {
          margin: 20px 0;
        }
        > :last-child {
          flex: 1;
          margin-bottom: 20px;
          white-space: normal;
        }
      }
      .buttonAction {
        box-shadow: 0 0 10px @gray-5;
        border-color: @gray-4;
        > :first-child {
          color: @gray-5;
        }
      }
    }
  }
}
.checkCard > :last-child {
  padding-top: 8px !important;
}
.steps {
  display: grid;
  gap: 16px;
  grid-template-columns: 1fr 1fr 1fr;
  @media (max-width: 1400px) {
    grid-template-columns: 1fr 1fr;
  }
  @media (max-width: 650px) {
    grid-template-columns: 1fr;
  }
  .stepCard {
    display: flex;
    border-radius: 30px;
    overflow: hidden;
    flex-direction: column;
    box-shadow: 0 2px 10px @gray-5;
    margin: 0 !important;
    > :first-child > :first-child {
      height: unset !important;
    }
    > :not(:first-child) {
      margin-left: 24px;
    }
    padding-bottom: 24px;
  }
}
.helpCardBook {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  margin-top: 16px;
}

@primary-color: #FE5816;@font-size-base: 16px;