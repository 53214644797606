.logoContainer {
  max-width: 400px;
}

.logoUploadClassName {
  :global(.ant-btn) {
    margin-right: 15px;
  }
  // display: block;
  // width: 100%;
  // > :global(.ant-upload){
  //   display: block;
  //   > :global(.ant-upload) {
  //     display: flex;
  //     flex: 1 0 100%;
  //     flex-direction: row;
  //     justify-content: space-between;
  //   }
  // }
}

@primary-color: #FE5816;@font-size-base: 16px;