@import 'components/layouts/SignDocuments.variables.less';
.circle {
  width: 55px;
  height: 55px;
  background: @primary-027;
  border-radius: 100px;
  place-items: center;
  justify-content: center;
  display: flex;
  color: white;
  font-size: 22px;
  &:global(.active-circle) {
    background: @primary-100;
  }
  @media screen and (max-device-width: 500px) {
    width: 40px;
    height: 40px;
  }
  svg {
    transform: scale(1.3);
    @media screen and (max-device-width: 500px) {
      transform: none;
    }
  }
}

.progress {
  display: flex;
  flex-direction: row;
  height: 45px;
  margin-top: -3px;
  @media screen and (max-device-width: 500px) {
    width: 100%;
    height: 40px;
  }
}
.dash {
  margin: auto;
  margin-left: 8px;
  margin-right: 8px;
  overflow: hidden;
  position: relative;
  white-space: nowrap;
  @media screen and (max-device-width: 500px) {
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
  }
  &:global(.active-dash) {
    &:after {
      color: @primary-089;
    }
  }

  &:after {
    color: @primary-027;
    content: '.......';
    display: inline-block;
    font-size: 8px;
    letter-spacing: 0px;
    line-height: 0px;
    vertical-align: 0px;
    @media screen and (max-device-width: 500px) {
      content: '.....';
      vertical-align: 4px;
    }
  }
}

@primary-color: #FE5816;@font-size-base: 16px;