.tabContainer {
  display: flex;
  flex-direction: column;
  width: 100% !important;
  > button {
    overflow: hidden;
    height: 50px !important;
  }

  @media (min-width: 320px) {
    flex-direction: row;

    flex-wrap: nowrap;
    > :first-child {
      border-right: none !important;
      border-top-right-radius: 0 !important;
      border-bottom-right-radius: 0 !important;
    }
    > :last-child {
      border-left: none !important;
      border-top-left-radius: 0 !important;
      border-bottom-left-radius: 0 !important;
    }
  }
}

@primary-color: #FE5816;@font-size-base: 16px;