.categoryList {
  display: flex;
  flex-direction: column;
}

.categoryOptions {
  padding: 10px;
  margin: -10px;
  user-select: none;
  border-radius: 4px;
  font-size: 20px;
}

.rightTopHeader {
  display: flex;
  flex-direction: row;
  float: right;
  > span {
    margin: 0 0 auto 10px;
  }
}

@primary-color: #FE5816;@font-size-base: 16px;