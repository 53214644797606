@import 'styles/variables.less';

.import-items {
  margin-top: 5px !important;
  margin-bottom: 5px !important;
  position: relative;
  .import-add-btn {
    position: absolute;
    top: 10px;
    right: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 30px;
    border: 1px solid black;
    cursor: pointer;
    outline: none;
    border-image: initial;
    svg {
      fill: var(--primary-color);
    }
  }
}
.import-duplicated .ant-collapse {
  border: 1px solid var(--primary-color) !important;
  border-bottom: 0;
  .ant-collapse-item {
    border-bottom: 0;
  }
}
.errorWrapper {
  margin-top: 5px;
}
.site-collapse-custom-panel {
  :global(.ant-collapse-content-box) {
    background-color: #fafafa;
  }
  > :first-child {
    display: flex;
    flex: 1;
    overflow: hidden;
    :global(.ant-collapse-extra) {
      margin-left: 10px;
      align-self: flex-end;
    }
  }
}

@primary-color: #FE5816;@font-size-base: 16px;