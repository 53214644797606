.stepActions {
  display: flex;
  .actionButtonsGroupe {
    margin-left: auto;
    display: flex;
    button {
      margin: 0 5px;
    }
  }
}

@primary-color: #FE5816;@font-size-base: 16px;