@import 'styles/variables.less';

.documentTypesSelect {
  > div > div {
    border-radius: 5px !important;
    padding: 4px 16px !important;
  }
}

.documentTemplates {
  .headerPanel {
    flex: 1;
    margin-top: 4px;
  }
  > :not(:first-child) {
    margin-top: 10px;
  }
  .documentTemplateCart {
    &.onClickableHeaderPanel > :first-child {
      cursor: auto !important;
    }
    border: none !important;
    background: #fff;
    border-radius: 5px !important;
    background-color: @shopping-cart-category-background;
    > :first-child {
      display: flex;
    }
    .documentTemplateDisabledButton {
      background: #fff !important;
    }
    .documentTemplateDisabledButton,
    .documentTemplateButton {
      display: flex;
      border-radius: 50%;
      justify-content: center;
      align-items: center;

      border: none;
      box-shadow: none;

      > svg {
        color: @gray-6;
      }
    }
  }
}
.bodyInput {
  margin: 0 !important;
}
.editDocument {
  margin-top: 2px;
  svg {
    color: @gray-8;
  }
}
.dot {
  display: inline-block;
  min-width: 15px;
  min-height: 15px;
  border-radius: 50%;

  border: 2px solid @gray-7;
  &.activeDot {
    background: green;
    border-color: green;
  }
}
.indicators {
  > div {
    display: inline-block;
    > span {
      display: inline-flex;
      margin-right: 36px;
      align-items: center;
      .indicator {
        margin-right: 8px;
      }
    }
  }
}
// :global(.ant-collapse-item-active).documentTemplateCart {
//   > :first-child {
//     background-color: var(--primary-color-alpha-50) !important;
//   }
// }

@primary-color: #FE5816;@font-size-base: 16px;