.topSettings {
  display: flex !important;
  justify-content: flex-end;
  align-items: center;

  margin-top: -60px;
  margin-bottom: 25px;
  .cartOptions {
    border: none !important;
    box-shadow: none !important;
    border-radius: 5px !important;
  }
}
.groupOptions {
  display: flex;
  flex-direction: row;

  @media (max-width: 570px) {
    flex-direction: column;
  }
  > div {
    flex: 1;
  }
  > :first-child {
    margin-right: 16px;
  }
}
.optionNote {
  > :nth-child(2) {
    margin-top: 10px;
  }
}
div.VATType {
  margin-top: 24px;
}

@primary-color: #FE5816;@font-size-base: 16px;