.headerInactiveName {
  height: auto;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding-left: 50px;
  max-width: 100%;
}

.headerActiveName {
  height: auto;
  word-break: break-word;
  padding-left: 50px;
}
.itemsContainer {
  border: 1px solid #f0f0f0;
}
.collapse {
  border-bottom: 1px solid #d9d9d9 !important;
  > :first-child {
    border-bottom: 0 !important;
    > :first-child > :nth-child(2) {
      padding: 0 30px 0 0 !important;
    }
  }
}
.import {
  white-space: nowrap !important;
}

@primary-color: #FE5816;@font-size-base: 16px;